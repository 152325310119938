import { FC, useEffect, useState } from "react";
import * as xlsx from 'xlsx';
import * as fs from 'fs';
import { BottomListComponent } from "@Components/BottomList.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDate, getDate } from "@Helpers/formatter";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ListRoleModel } from "@Pages/role/data/role.model";
import { GetAllRolesUsecase } from "@Pages/role/domain/GetAllRoles.usecase";
import { GetExpiredUserUsecase } from "../domain/getExpiredUser.usecase";
import { ListUserExpiredModel } from "../data/expiredUser.model";
import { ContentHeader } from "@Components/ContentHeader.component";

export const ExpiredMembersPage: FC = ({
}) => {
  const toasterContext = UseToasterContext();

  const [needRefetch, setNeedRefetch] = useState(true);
  const [page, setPage] = useState<any>("");
  const [limit, setLimit] = useState<any>("");

  const [apiData, setApiData] = useState<ListRoleModel>();
  const [expiredMember, setExpiredMember] = useState<ListUserExpiredModel>();
  const [filterRole, setFilterRole] = useState<any>("all");
  const [filterStartDate, setFilterStartDate] = useState<any>(getDate("start"));
  const [filterEndDate, setFilterEndDate] = useState<any>(getDate("end"));

  useEffect(() => {
    async function fetchData() {
      try {
        let response = await GetAllRolesUsecase("");
        setApiData(response);
      } catch (errors) {
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDataExpired() {
      try {
        if(filterStartDate>filterEndDate && filterEndDate !== "" ){
          throw new Error('Start Date tidak boleh lebih besar dari End Date')
        }
        let clearedString = `${"?role=" + filterRole 
          + "&startdate=" + filterStartDate + "&enddate=" + filterEndDate 
          + "&page=" + page 
          + "&limit=" + limit 
        }`

        let response = await GetExpiredUserUsecase(clearedString);
        
        setExpiredMember(response)

        setNeedRefetch(false);
      } catch (errors) {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            errors.message,
            "",
            ToasterType.DANGER
          ),
        ]);
      }
    }
    fetchDataExpired();
  }, [needRefetch]);

  const canGoPrev = () =>
    (expiredMember && expiredMember.meta && expiredMember.meta.page > 1) ?? false;

  const onPrevPage = () => {
    if (expiredMember && expiredMember.meta && canGoPrev()) {
      let prevPage = expiredMember.meta.page - 1;
      if (prevPage >= 1) {
        setNeedRefetch(true);
        setPage(prevPage);
      }
    }
  };

  const canGoNext = () =>
    (expiredMember && expiredMember.meta && expiredMember.meta.end < expiredMember.meta.total) ?? false;

  const onNextPage = () => {
    if (expiredMember && expiredMember.meta && canGoNext()) {
      let nextPage = expiredMember.meta.page + 1;
      setNeedRefetch(true);
      setPage(nextPage);
    }
  };

  const onChangeLimit = (newValue: string) => {
    setNeedRefetch(true);
    setLimit(newValue)
    setPage("1")
  };

  const convertToXlsxCsv = (jsonData: any, outputFilePath: string, fileType: 'xlsx' | 'csv') => {
    // Create a new workbook
    const workbook = xlsx.utils.book_new();
  
    // Add the JSON data to a new sheet
    const sheet = xlsx.utils.json_to_sheet(jsonData);
  
    // Add the sheet to the workbook
    xlsx.utils.book_append_sheet(workbook, sheet, 'Sheet 1');
  
    // Write the workbook to a file
    if (fileType === 'xlsx') {
      xlsx.writeFile(workbook, outputFilePath);
    } else if (fileType === 'csv') {
      const csvData = xlsx.utils.sheet_to_csv(sheet);
      fs.writeFileSync(outputFilePath, csvData);
    }
  };
  
  return (
    <div className="mt-4 px-4">
      <ContentHeader title={"Expired Members List"} />
      <div className="h-6"></div>
       <div className="border p-3 align-middle flex flex-col justify-between shadow-sm md:flex-row">
          <div className="flex gap-4">
            <select
              className="mr-1 border rounded pl-1 pr-1"
              value={filterRole}
              onChange={(event) => setFilterRole(event.target.value)}
            >
              <option value="all">3 Main Roles</option>
              {apiData?.meta &&
                apiData.list.map((item :any, index:any) => {
                  return (
                    <option key={index} value={item.title}>
                      {item.title}
                    </option>
                  );
                })}
            </select>

            <div className="border p-2 flex items-center">
              <label className="pr-2">
                Start Date : 
              </label>
              <input
                type="date"
                value={filterStartDate}
                onChange={(event)=>{setFilterStartDate(event.target.value)}}
              >
              </input>            
            </div>

            <div className="border p-2 flex items-center">
              <label className="pr-2">
                End Date : 
              </label>
              <input
                type="date"
                value={filterEndDate}
                onChange={(event)=>{setFilterEndDate(event.target.value)}}
              >
              </input>            
            </div>

            <button
              className="button-yellow rounded w-fit p-3 "
              onClick={()=>setNeedRefetch(true)}
              style={{
                backgroundColor: "#ffdd28",
              }}
            >
              Cari
            </button>

            <div className="flex gap-2 items-center">
              <div>
                Total Data :
              </div>
              {expiredMember && (expiredMember?.meta?.total)}
            </div>
          </div>
          <button
              className="button-yellow rounded w-fit p-3 "
              onClick={()=>{convertToXlsxCsv(expiredMember?.list, 'output.xlsx', 'xlsx')}}
              style={{
                backgroundColor: "#ffdd28",
              }}
            >
              Download Data
            </button>
        </div>

        {!expiredMember && (<MidasShimmering/>)}

        {expiredMember && expiredMember.list.length === 0 && (
          <div className="text-center bg-gray-300 p-4">
            Data User Not Found!
          </div>
        )}

        {expiredMember && expiredMember.list.length > 0 && (
          <table className="table-auto border w-full">
            <thead className="p-2 shadow-sm bg-gray-100 sticky">
                <tr>
                {expiredMember?.meta?.filterRole =="all" && (<th className="border-l border-t border-b border-black hidden md:table-cell">Role</th>)}
                  <th className="border-l border-t border-b border-black hidden md:table-cell">Expired On</th>
                  <th className="border-l border-t border-b border-black">Nama</th>
                  <th className="border-l border-t border-b border-black hidden md:table-cell">No. Telp</th>
                </tr>
            </thead>
            <tbody>
              {expiredMember && expiredMember.list.map((key, index) => {
                return (<tr key={`item-${key}-${index}`}>
                    {expiredMember?.meta?.filterRole =="all" && (<td className="border-b text-center">{key?.title}</td>)}
                    <td className="border-b text-center hidden md:table-cell">{convertDate(key?.expired_date)}</td>
                    <td className="border-b text-center">{key?.fullname}</td>
                    <td className="border-b text-center hidden md:table-cell"> {key?.phone} </td>
                </tr>)
              })}
            </tbody>
          </table>
        )}

        {expiredMember && expiredMember.meta && (
          <BottomListComponent
            shouldShow={expiredMember.meta !== undefined}
            onLimitChange={onChangeLimit}
            canGoPrev={canGoPrev()}
            onGoToPrev={onPrevPage}
            canGoNext={canGoNext()}
            onGoToNext={onNextPage}
            meta={expiredMember.meta}
          />
        )}
    </div>
  );
};
