/* eslint-disable react-hooks/exhaustive-deps */
import { BottomListComponent } from "@Components/BottomList.component";
import { YellowHoverButton } from "@Components/buttons/AddNewButton.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { SearchInputComponent } from "@Components/inputs/SearchInputs.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE } from "@Helpers/regex";
import { buildQueryParamsFromUrl, buildUrlSearchParams } from "@Helpers/utils";
import { createModal } from "@Helpers/view";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { copyToClipboard } from "@Utils/Utils";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { BsGridFill } from "react-icons/bs";
import {
  FaExclamationTriangle,
  FaEye,
  FaRegClipboard,
  FaRegTrashAlt,
  FaTable,
  FaVideo,
} from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ListResourceModel } from "../data/resource.model";
import { DeleteResourceUsecase } from "../domain/DeleteResource.usecase";
import { GetAllResourcesUsecase } from "../domain/GetAllResources.usecase";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";

export const ResourcePage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const modalContext = UseModalContext();
  const toasterContext = UseToasterContext();

  const searchRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [isListView, setIsListView] = useState(true);
  const [needRefetch, setNeedRefetch] = useState(true);
  const [apiData, setApiData] = useState<ListResourceModel>();

  useEffect(() => {
    if (needRefetch) {
      async function fetchData() {
        let queryParams = buildQueryParamsFromUrl(searchParams.entries());
        if (queryParams !== "") {
          queryParams = "?" + queryParams;
        }

        try {
          let response = await GetAllResourcesUsecase(queryParams);
          setApiData(response);
        } catch (errors) {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to fetch media",
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        }

        setNeedRefetch(false);
      }
      fetchData();
    }
  }, [needRefetch]);

  const getQueryMime = () => {
    let filter = searchParams.get("filter");
    if (filter === null) {
      filter = "all";
    }
    return filter;
  };

  const onCopyToClipboard = (
    textToCopy: string,
    title: string,
    description: string,
    type: ToasterType
  ) => {
    copyToClipboard(textToCopy);
    toasterContext.setToastList([
      ...toasterContext.toastList,
      new ToasterModel(title, description, type),
    ]);
  };

  const onViewMedia = (url: string) => {
    var newTab = window.open(url, "_blank");
    newTab?.focus();
  };

  const onDelete = async (index: number) => {
    try {
      await DeleteResourceUsecase(apiData?.list[index].id);
      setNeedRefetch(true);
      modalContext.setModal(null);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Failed to delete resource",
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  const onShowDeleteModal = (index: number) => {
    let icon = (
      <div className="text-red-500">
        <FaExclamationTriangle size={48} />
      </div>
    );
    let title = "Hapus Media";
    let description = (
      <>
        Apakah anda yakin ingin menghapus{" "}
        <span className="font-bold">{apiData?.list[index].name}</span> ?
      </>
    );
    let primaryCta = (
      <button
        className="button-red-outline-with-hover"
        onClick={() => onDelete(index)}
      >
        Hapus
      </button>
    );
    let secondaryCta = (
      <button
        className="button-outline-with-hover"
        onClick={() => modalContext.setModal(null)}
      >
        Batal
      </button>
    );

    modalContext.setModal(
      createModal(icon, title, description, primaryCta, secondaryCta)
    );
  };

  const canGoPrev = () =>
    (apiData && apiData.meta && apiData.meta.page > 1) ?? false;

  const onPrevPage = () => {
    if (apiData && apiData.meta && canGoPrev()) {
      let prevPage = apiData.meta.page - 1;
      if (prevPage >= 1) {
        setNeedRefetch(true);
        navigate({
          search:
            "?" +
            buildUrlSearchParams(
              searchParams.entries(),
              "page",
              prevPage.toString()
            ),
        });
      }
    }
  };

  const canGoNext = () =>
    (apiData && apiData.meta && apiData.meta.end < apiData.meta.total) ?? false;

  const onNextPage = () => {
    if (apiData && apiData.meta && canGoNext()) {
      let nextPage = apiData.meta.page + 1;
      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(
            searchParams.entries(),
            "page",
            nextPage.toString()
          ),
      });
    }
  };

  const onFilterMediaType = (selectedMime: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" +
        buildUrlSearchParams(searchParams.entries(), "filter", selectedMime),
    });
  };

  const onChangeLimit = (newValue: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" + buildUrlSearchParams(searchParams.entries(), "limit", newValue),
    });
  };

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      let clearedString = e.target.value
        // .replace(REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE, "")
        .replaceAll(" ", "+");

      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(searchParams.entries(), "search", clearedString),
      });
    }
  };

  return (
    <div className="m-4">
      {!apiData && <MidasShimmering />}
      {apiData && (
        <>
          <ContentHeader
            title={"Resource Library"}
            rightButton={
              <div className="flex">
                <YellowHoverButton
                  label={"Add Bulk"}
                  destination="/resources/add-bulk"
                  customClass="mr-2"
                />

                <YellowHoverButton
                  customClass="ml-2"
                  label={"Add New"}
                  destination="/resources/add"
                />
              </div>
            }
          />
          <div className="h-6"></div>

          {/* top config */}
          <div className="border p-3 align-middle flex flex-col justify-between shadow-sm md:flex-row">
            <div className="flex">
              {/* list table view */}
              <div
                className="mr-1 w-6 h-6 text-center"
                onClick={() => setIsListView(true)}
              >
                <FaTable size={24} color={isListView ? "#FFCC00" : "#50575E"} />
              </div>

              {/* grid view */}
              <div
                className="mr-1 w-6 h-6 text-center"
                onClick={() => setIsListView(false)}
              >
                <BsGridFill
                  size={24}
                  color={isListView ? "#50575E" : "#FFCC00"}
                />
              </div>

              {/* media type filter */}
              <select
                className="mr-1 border rounded pl-1 pr-1"
                value={getQueryMime()}
                onChange={(event) => onFilterMediaType(event.target.value)}
              >
                <option value="all">All Media Types</option>
                {apiData.meta &&
                  apiData.meta.available_mimes.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="border rounded mt-2 md:mt-0 flex">
              <SearchInputComponent
                searchRef={searchRef}
                onKeyDown={handleSearch}
              />
            </div>
          </div>

          {/* list items */}
          <div className="mt-4">
            {apiData.list.length === 0 && (
              <div className="text-center bg-gray-300 p-4">
                No Media Resource Available!
              </div>
            )}
            {apiData.list.length > 0 && isListView && (
              <table className="table-auto border w-full">
                <thead className="p-2 shadow-sm bg-gray-100 sticky">
                  <tr>
                    <th></th>
                    <th>File</th>
                    <th className="hidden md:table-cell">Author</th>
                    <th className="hidden md:table-cell">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.list.map((item, index) => (
                    <tr key={item.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="p-2 flex">
                        {item.mime_type.includes("video") ? (
                          <div className="w-24 place-self-center p-5 bg-gray-400 rounded-xl">
                            <FaVideo />
                          </div>
                        ) : (
                          <img
                            src={item.link_url}
                            className="object-cover aspect-square rounded-xl"
                            alt=""
                            width={100}
                            height={100}
                          />
                        )}
                        <div className="pl-3">
                          <div className="font-bold text-ellipsis">
                            {item.name}
                          </div>
                          <div className="flex mt-3">
                            <Link to={"/resources/detail/" + item.id}>
                              <button
                                className="text-blue-400 mr-2 w-6 h-6"
                                title="Edit"
                              >
                                <BsPencilSquare />
                              </button>
                            </Link>
                            <button
                              className="text-red-400 mr-2 w-6 h-6"
                              title="Delete Permanently"
                              onClick={() => onShowDeleteModal(index)}
                            >
                              <FaRegTrashAlt />
                            </button>
                            <button
                              className="text-blue-400 mr-2 w-6 h-6"
                              title="View"
                              onClick={() => onViewMedia(item.link_url)}
                            >
                              <FaEye />
                            </button>
                            <button
                              className="text-blue-400 w-6 h-6"
                              title="Copy to clipboard"
                              onClick={() =>
                                onCopyToClipboard(
                                  item.link_url,
                                  "Berhasil",
                                  "URL Media berhasil disalin.",
                                  ToasterType.SUCCESS
                                )
                              }
                            >
                              <FaRegClipboard />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {item.created_by.fullname}
                      </td>
                      <td className="text-center hidden md:table-cell">
                        {convertDateFromApiToView(item.created_at)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {apiData.list.length > 0 && !isListView && (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                {apiData.list.map((item) => (
                  <Link to={"/resources/detail/" + item.id}>
                    {item.mime_type.includes("video") ? (
                      <div
                        key={item.id}
                        className="w-24 place-self-center p-5 bg-gray-400 rounded-xl"
                      >
                        <FaVideo />
                      </div>
                    ) : (
                      <img
                        src={item.link_url}
                        key={item.id}
                        className="object-cover"
                        alt=""
                      />
                    )}
                  </Link>
                ))}
              </div>
            )}

            {apiData.meta && (
              <BottomListComponent
                shouldShow={apiData.meta !== undefined}
                onLimitChange={onChangeLimit}
                canGoPrev={canGoPrev()}
                onGoToPrev={onPrevPage}
                canGoNext={canGoNext()}
                onGoToNext={onNextPage}
                meta={apiData.meta}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
