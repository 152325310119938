export const REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE = /^[A-Za-z0-9_]*$/g;

export const REGEX_EXCEPT_ALPHA_AND_SPACE = /[^a-zA-Z ]/g;

export const REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE = /[^a-zA-Z0-9 ]/g;

export const REGEX_EXCEPT_ALPHA_NUMERIC_SPACE_DASH = /[^a-z0-9-]*$/g;

export const REGEX_REMOVE_EXTENTION = /\.[^.]*/g;

export const REGEX_REPLACE_ALL_EXCEPT = /[^a-zA-Z0-9]/g;