import { AxiosProgressEvent } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContentHeader } from "@Components/ContentHeader.component";
import { UploadResourceCustomUsecase } from "../domain/UploadResourceCustom.usecase";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { REGEX_REMOVE_EXTENTION, REGEX_REPLACE_ALL_EXCEPT } from "@Helpers/regex";

export type AddBulkResourceModel = {
    file: File;
    isUploading: boolean;
    uploadProgress: number;
    isDoneUpload: boolean;
    isFailedUpload: boolean;
    failedMessage: string;
};

export const AddBulkResourcePage = () => {
    const navigate = useNavigate();

    const [uploadState, setUploadState] = useState<AddBulkResourceModel[]>([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isStartUploadActive, setIsStartUploadActive] = useState(false);

    useEffect(() => {
        let isUploadingOnProgress = false;
        let isSelectedNotEmpty = uploadState.length > 0;
        let isFileReadyToUpload = false;

        uploadState.forEach((state) => {
            if (state.isUploading) isUploadingOnProgress = true;
            if (state.isFailedUpload || (!state.isUploading && !state.isDoneUpload)) isFileReadyToUpload = true
        });

        setIsUploading(isUploadingOnProgress);
        setIsStartUploadActive(isSelectedNotEmpty && isFileReadyToUpload);
    }, [uploadState]);

    const uploadProgressCallback = (e: AxiosProgressEvent, index: number) => {
        let progress = Math.round((100 * e.loaded) / (e.total ?? 1));

        let allUploadState = [...uploadState];
        allUploadState[index] = {
            ...allUploadState[index],
            uploadProgress: progress
        }

        setUploadState(allUploadState);
    };

    const formatTitle = (fileName: string) => fileName.replaceAll(REGEX_REMOVE_EXTENTION, '').replaceAll(REGEX_REPLACE_ALL_EXCEPT, "_");

    const processFileToUpload = async () => {
        let newUploadStatePromise = uploadState.map(async (state, index) => {
            let newState = await startUpload(state, index);
            return newState;
        });
        let newUploadState = await Promise.all(newUploadStatePromise);

        setUploadState(newUploadState);
    }

    const startUpload = async (state: AddBulkResourceModel, index: number) => {
        if (state.isDoneUpload || state.isUploading) return state;

        let newState = state;

        try {
            newState = {
                ...newState,
                isUploading: true,
            };

            await UploadResourceCustomUsecase(
                uploadProgressCallback,
                formatTitle(state.file.name),
                state.file,
                index,
            );

            newState = {
                ...newState,
                isDoneUpload: true,
                isUploading: false,
                isFailedUpload: false,
                failedMessage: "",
            };
        } catch (errors) {
            newState = {
                ...newState,
                isUploading: false,
                isDoneUpload: false,
                isFailedUpload: true,
                failedMessage: errors.message,
            };
        }

        return newState;
    }

    const navigateToResourceList = () => {
        navigate("/resources");
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedFiles = Array.from(event.target.files || []);
        let filesWithProgress: AddBulkResourceModel[] = [];

        for (let key in selectedFiles) {
            let currentItem = selectedFiles[key];
            let isUnique = !uploadState.some(item => item.file.name == currentItem.name);

            if (isUnique) {
                filesWithProgress.push({
                    file: currentItem,
                    isUploading: false,
                    uploadProgress: 0,
                    isDoneUpload: false,
                    isFailedUpload: false,
                    failedMessage: "",
                })
            }
        }

        setUploadState((prevList) => [...prevList, ...filesWithProgress]);
    };

    return (
        <div className="bg-white m-4">
            <ContentHeader
                title="Add Bulk Media"
                rightButton={
                    <div className="flex">
                        <button
                            className="button-yellow-outline-with-hover mr-2"
                            onClick={processFileToUpload}
                            type="button"
                            disabled={!isStartUploadActive}
                        >
                            Start Upload
                        </button>

                        <button
                            className="button-red-outline-with-hover"
                            type="button"
                            onClick={navigateToResourceList}
                        >
                            Cancel
                        </button>
                    </div>
                }
            />
            <div className="h-6"></div>


            <div>
                <input
                    type="file"
                    className="hidden"
                    id="fileInput"
                    multiple
                    onChange={handleFileChange}
                    disabled={isUploading}
                />
                <label className="button-yellow-outline-with-hover" htmlFor="fileInput">Choose Files</label>
            </div>

            <div className="mt-6">
                {uploadState.map((state, index) => (<div key={`select-file-${index}`} className="border rounded p-2 m-1">
                    <div className="w-full flex">{state.file.name} {state.isDoneUpload && "- (Uploaded)"}
                        <div className="m-auto mx-3  text-red-500">
                            <button onClick={() => {
                                setUploadState(uploadState.filter(
                                    function (item) {
                                        return item !== state
                                    }
                                ))
                            }} >
                                <AiOutlineCloseCircle />
                            </button>
                        </div>
                    </div>
                    {state.isUploading && !state.isDoneUpload && !state.isFailedUpload && <progress value={state.uploadProgress} max={100} className="midas-progress-bar" />}
                    {state.isDoneUpload && <div className="w-full h-6 rounded overflow-hidden bg-green-500" /> }
                    {state.isFailedUpload && state.failedMessage &&
                        <>
                            <div className="w-full h-6 rounded overflow-hidden bg-red-500" />
                            <a className="w-full h-6 rounded overflow-hidden text-red-500" > {state.failedMessage} </a>
                        </>
                    }
                </div>
                ))}
            </div>

        </div>
    );
};
