export const AvailableRoles = {
  Intools: {
    Course: [
      {
        label: "List",
        access: ["intools::courses", "api::courses::list"].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::courses::detail",
          "api::courses::detail",
          "api::courses::update",
          "api::courses::delete",
          "api::courses::createSection",
          "api::courses::updateSection",
          "api::courses::deleteSection",
          "api::courses::createItem",
          "api::courses::updateItem",
          "api::courses::deleteItem",
        ].join(";"),
      },
      {
        label: "Add",
        access: [
          "intools::courses::add",
          "api::courses::create",
          "api::courses::update",
          "api::courses::delete",
          "api::courses::createSection",
          "api::courses::updateSection",
          "api::courses::deleteSection",
          "api::courses::createItem",
          "api::courses::updateItem",
          "api::courses::deleteItem",
        ].join(";"),
      },
    ],
    Orders: [
      {
        label: "List",
        access: ["intools::orders", "api::orders::list"].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::orders::detail",
          "api::orders::detail",
          "api::orders::update",
        ].join(";"),
      },
    ],
    Pages: [
      {
        label: "List",
        access: [
          "intools::pages",
          "api::pages::list",
          "api::pages::delete",
        ].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::pages::detail",
          "api::pages::detail",
          "api::pages::update",
          "api::pages::delete",
        ].join(";"),
      },
      {
        label: "Add",
        access: [
          "intools::pages::add",
          "api::pages::create",
          "api::pages::update",
          "api::pages::delete",
        ].join(";"),
      },
      { label: "Preview", access: "intools::pages::preview" },
    ],
    Products: [
      {
        label: "List",
        access: [
          "intools::products",
          "api::products::list",
          "api::products::delete",
        ].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::products::detail",
          "api::products::detail",
          "api::products::update",
          "api::products::delete",
        ].join(";"),
      },
      {
        label: "Add",
        access: [
          "intools::products::add",
          "api::products::create",
          "api::products::update",
          "api::products::delete",
        ].join(";"),
      },
    ],
    Records: [
      {
        label: "List",
        access: [
          "intools::records",
          "api::records::list",
          "api::records::delete",
        ].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::records::detail",
          "api::records::detail",
          "api::records::update",
          "api::records::delete",
        ].join(";"),
      },
      {
        label: "Add",
        access: [
          "intools::records::add",
          "api::records::create",
          "api::records::update",
          "api::records::delete",
        ].join(";"),
      },
    ],
    Resources: [
      {
        label: "List",
        access: [
          "intools::resources",
          "api::resources::list",
          "api::resources::delete",
        ].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::resources::detail",
          "api::resources::detail",
          "api::resources::update",
          "api::resources::delete",
        ].join(";"),
      },
      {
        label: "Add",
        access: [
          "intools::resources::add",
          "api::resources::create",
          "api::resources::update",
          "api::resources::delete",
        ].join(";"),
      },
    ],
    Roles: [
      {
        label: "List",
        access: [
          "intools::roles",
          "api::roles::list",
          "api::roles::delete",
        ].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::roles::detail",
          "api::roles::detail",
          "api::roles::update",
          "api::roles::delete",
        ].join(";"),
      },
      {
        label: "Add",
        access: [
          "intools::roles::add",
          "api::roles::create",
          "api::roles::update",
          "api::roles::delete",
        ].join(";"),
      },
    ],
    Settings: [
      {
        label: "General",
        access: [
          "intools::settings",
          "api::settings::list",
          "api::settings::create",
          "api::settings::detail",
          "api::settings::update",
          "api::settings::delete",
        ].join(";"),
      },
      {
        label: "Static Pages",
        access: [
          "intools::settings::static-pages",
          "api::settings::list",
          "api::settings::create",
          "api::settings::detail",
          "api::settings::update",
          "api::settings::delete",
        ].join(";"),
      },
    ],
    Users: [
      {
        label: "List",
        access: ["intools::users", "api::users::list"].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::users::detail",
          "api::users::detail",
          "api::users::update",
          "api::users::updateMemberRole",
        ].join(";"),
      },
      {
        label: "Expired",
        access: ["intools::expired-members", "api::expired-members::list"].join(";"),
      },
    ],
    Vouchers: [
      {
        label: "List",
        access: [
          "intools::vouchers",
          "api::vouchers::list",
          "api::vouchers::delete",
        ].join(";"),
      },
      {
        label: "Detail",
        access: [
          "intools::vouchers::detail",
          "api::vouchers::detail",
          "api::vouchers::update",
          "api::vouchers::delete",
        ].join(";"),
      },
      {
        label: "Add",
        access: [
          "intools::vouchers::add",
          "api::vouchers::create",
          "api::vouchers::update",
          "api::vouchers::delete",
        ].join(";"),
      },
    ],
    Integrations: [
      {
        label: "Get Response",
        access: [
          "intools::get-response",
          "api::getResponse::account",
          "api::getResponse::autoresponder",
          "api::getResponse::campaigns",
          "api::getResponse::campaignContact",
          "api::getResponse::contacts",
          "api::getResponse::contactActivity",
          "api::getResponse::landingpages",
          "api::getResponse::detailLandingpages",
          "api::getResponse::publishLandingpages",
          "api::getResponse::unPublishLandingpages",
          "api::getResponse::products::list",
          "api::getResponse::products::create",
          "api::getResponse::products::detail",
          "api::getResponse::products::update",
          "api::getResponse::products::delete",
          "api::getResponse::shops",
          "api::getResponse::createShop",
          "api::getResponse::detailShop",
          "api::getResponse::updateShop",
          "api::getResponse::deleteShop",
        ].join(";"),
      },
    ],
  },
  FrontEnd: {
    "Member-Area": [
      {
        label: "Starting Guide",
        access: ["frontend::member-area"].join(";"),
      },
      {
        label: "Investment Philosophy",
        access: ["frontend::member-area::philosophy"].join(";"),
      },
      {
        label: "Courses",
        access: [
          "frontend::member-area::courses",
          "api::courses::list",
          "api::courses::detail",
        ].join(";"),
      },
      {
        label: "Jarvis",
        access: [
          "frontend::member-area::jarvis",
          "api::jarvis::list",
          "api::jarvis::detail",
        ].join(";"),
      },
      {
        label: "Grup Diskusi",
        access: ["frontend::member-area::groups"].join(";"),
      },
      {
        label: "Portofolio",
        access: [
          "frontend::member-area::portfolio",
          "api::portfolios::list",
        ].join(";"),
      },
      {
        label: "Rekaman",
        access: ["frontend::member-area::records", "api::records::list"].join(
          ";"
        ),
      },
      {
        label: "Stock Key",
        access: [
          "frontend::member-area::stock-key",
          "api::stock-key::load",
        ].join(";"),
      },
    ],
    "Analisa-Saham": [
      {
        label: "Unlimited",
        access: [
          "frontend::member-area::analysis",
          "frontend::member-area::analysis::all",
          "api::analysis::list",
        ].join(";"),
      },
      {
        label: "Selected Only",
        access: [
          "frontend::member-area::analysis",
          "frontend::member-area::analysis::selected",
          "api::analysis::list",
        ].join(";"),
      },
      {
        label: "Portfolio Only",
        access: [
          "frontend::member-area::analysis",
          "frontend::member-area::analysis::portfolio",
          "api::analysis::list",
        ].join(";"),
      },
      {
        label: "Free Trials",
        access: [
          "frontend::member-area::analysis",
          "frontend::member-area::analysis::trial",
          "api::analysis::trial",
        ].join(";"),
      },
      {
        label: "Watchlist",
        access: [
          "frontend::member-area::analysis",
          "frontend::member-area::analysis::watchlist",
          "api::analysis::watchlist",
        ].join(";"),
      },
    ],
    Tools: [
      {
        label: "Stock Treasure Maps",
        access: ["frontend::member-area::tools::stock-treasure-sheet"].join(";"),
      },
      {
        label: "Stock Portfolio",
        access: ["frontend::member-area::tools::stock-portfolio"].join(";"),
      },
      {
        label: "Dividend Discount Model",
        access: ["frontend::member-area::tools::dividen-discount-model"].join(
          ";"
        ),
      },
      {
        label: "Valuasi",
        access: ["frontend::member-area::tools::valuasi"].join(";"),
      },
      {
        label: "Stock Treasure Maps Web",
        access: ["frontend::member-area::tools::stock-treasure-web"].join(";"),
      },
    ],
  },
};
