/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { GetAllProductUsecase } from "@Pages/product/domain/GetAllProducts.usecase";
import { ProductModel } from "@Pages/product/data/product.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { VoucherProductRequest } from "../data/voucher.model";
import { AddVoucherUsecase } from "../domain/AddVoucher.usecase";
import {
  ProductListVoucher,
  SelectedProductListVoucher,
} from "./components/ProductVoucher.modal";
import { UseModalContext } from "@Pages/common/contexts/modal.context";
import { createModal } from "@Helpers/view";
import { REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE } from "@Helpers/regex";
import { AiOutlineMinusCircle } from "react-icons/ai";

export type SelectedProducts = {
  id: number;
  title: string;
  value?: number | null;
  index?: string;
};

export type ListSelectedProductVoucher = {
  [key: string]: SelectedProducts;
};

export type AddVoucherFormsInput = {
  title: string;
  code: string;
  voucher_value: number;
  voucher_type: string;
  maximum_use: string;
  expired_date: string;
  description?: string;
  products: any;
};

export const AddVoucherPage = () => {
  const navigate = useNavigate();
  const toasterContext = UseToasterContext();
  const modalContext = UseModalContext();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<AddVoucherFormsInput>();

  const [selectedProduct, setSelectedProduct] =
    useState<SelectedProductListVoucher>({});
  const [productList, setProductList] = useState<ProductModel[]>([]);

  useEffect(() => {
    GetAllProductUsecase("?limit=100&status=publish")
      .then((response) => {
        setProductList(response.list);
      })
      .catch((errors) =>
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Error",
            "Failed to fetch products",
            ToasterType.DANGER
          ),
        ])
      );
  }, []);

  const onSubmit = (data: AddVoucherFormsInput) => {
    if (
      watch("products") === "" ||
      watch("products") === null ||
      watch("products").size === 0
    ) {
      return;
    }

    let formData = generateBasicFormData();
    formData.append("status", "publish");
    formData.append("move_to_trash", "false");

    sendDataToServer(formData);
  };

  const onSaveToDraft = async () => {
    let validationResult = await trigger();

    if (
      watch("products") === null ||
      watch("products") === "" ||
      watch("products").size === 0 ||
      validationResult !== true
    ) {
      return;
    }

    let formData = generateBasicFormData();
    formData.append("status", "draft");
    formData.append("move_to_trash", "false");

    sendDataToServer(formData);
  };

  const onCancel = () => navigate(-1);

  const generateBasicFormData = () => {
    let formData = new FormData();

    let maxUse = watch("maximum_use") !== "" ? watch("maximum_use") : "0";

    formData.append("title", watch("title"));
    formData.append("code", watch("code"));
    formData.append("type", watch("voucher_type"));
    formData.append("value", watch("voucher_value").toString());
    formData.append("max_usage", maxUse);

    let expiredDate = watch("expired_date");
    if (expiredDate !== "") {
      expiredDate += ":00+07:00";
      formData.append("expires_at", expiredDate);
    }

    let products = [];
    let currentSelectedProducts = watch("products");
    for (let key in currentSelectedProducts) {
      let item = currentSelectedProducts[key];
      let product: VoucherProductRequest = {
        id: item.id,
      };
      if (item.value !== null && item.value > 0) {
        product.value = item.value;
      }
      products.push(product);
    }
    formData.append("products", JSON.stringify(products));
    formData.append("description", watch("description") ?? "");

    return formData;
  };

  const sendDataToServer = (formData: FormData) => {
    AddVoucherUsecase(formData)
      .then((message) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Voucher successfully created!",
            message,
            ToasterType.SUCCESS
          ),
        ]);
        popWithDelay();
      })
      .catch((errors) => {
        toasterContext.setToastList([
          ...toasterContext.toastList,
          new ToasterModel(
            "Failed to save voucher!",
            errors.message,
            ToasterType.DANGER
          ),
        ]);
      });
  };

  const popWithDelay = () => {
    setTimeout(function () {
      navigate(-1);
    }, 1000);
  };

  const onSelectedProductChanged = useCallback(
    (value: SelectedProductListVoucher) => {
      let currentSelectedProducts = watch("products") ? watch("products") : {};
      let newSelectedProducts: ListSelectedProductVoucher = {};

      for (let key in value) {
        let item = value[key];
        let productInForm = currentSelectedProducts[item.id];
        if (productInForm !== null) {
          newSelectedProducts[item.id] = {
            id: item.id,
            title: item.title,
            value: item.value,
            index: key,
          };
        } else {
          newSelectedProducts[item.id] = {
            id: item.id,
            title: item.title,
            value: 0,
            index: key,
          };
        }
      }

      setValue("products", newSelectedProducts);
      setSelectedProduct(value);
    },
    [setSelectedProduct]
  );

  const addNewProduct = () => {
    let title = "Add Product";
    let description = (
      <ProductListVoucher
        parentSelectedProducts={selectedProduct}
        onSelectProductChanged={onSelectedProductChanged}
        productList={productList}
      />
    );
    let primaryCta = (
      <div className="absolute bottom-[700px] w-fit bg-white">
         <button
          className="button-outline-with-hover"
          onClick={() => modalContext.setModal(null)}
        >
          Close
        </button>
      </div>
     
    );

    modalContext.setModal(
      createModal(null, title, description, primaryCta, null)
    );
  };

  const onRemoveProduct = (key: string) => {
    let currentSelectedProducts = watch("products") ? watch("products") : {};
    let currentSelectedProductsState = { ...selectedProduct };

    delete currentSelectedProductsState[currentSelectedProducts[key].index];
    delete currentSelectedProducts[key];

    setValue("products", currentSelectedProducts);
    setSelectedProduct(currentSelectedProductsState);
  };

  return (
    <div className="bg-white m-4">
      <div className="flex justify-between">
        <div className="text-bold text-xl md:text-3xl">Add New Voucher</div>
        <div>
          <button
            className="button-red-outline-with-hover"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>

      {/* new voucher form */}
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        {/* title field */}
        <div className="font-medium my-auto w028">
          Title<span className="text-red-500">*</span>
        </div>
        <input
          type="text"
          className={`input-field-full-width 
            ${errors.title && "border-red-500"}`}
          {...register("title", { required: "Title is required." })}
        />
        {errors.title && (
          <p className="text-red-500 text-xs">{errors.title?.message}</p>
        )}

        {/* code field */}
        <div className="font-medium my-auto w028 mt-2">
          Code<span className="text-red-500">*</span>
        </div>
        <input
          type="text"
          className={`input-field-full-width uppercase
            ${errors.code && "border-red-500"}`}
          {...register("code", {
            required: "Voucher code is required.",
            pattern: {
              value: REGEX_ALPHA_NUMERIC_DASH_WITHOUT_SPACE,
              message:
                "Invalid title format, only alphanumeric and dash allowed",
            },
          })}
        />
        {errors.code && (
          <p className="text-red-500 text-xs">{errors.code?.message}</p>
        )}

        {/* Value field */}
        <div className="font-medium w-28 mt-2">
          Value<span className="text-red-500">*</span>
        </div>
        <div className="flex">
          <div className="w-full">
            <input
              className={`input-field-full-width 
                ${errors.voucher_value && " border-red-500"}`}
              type="number"
              {...register("voucher_value", {
                required: "Voucher value is required.",
                min: {
                  value: 1,
                  message: "Voucher value must be more than zero.",
                },
              })}
            />
          </div>
          <div className="w-full pl-2">
            <select
              className={`input-field-full-width 
                ${errors.voucher_type && "border-red-500"}`}
              {...register("voucher_type", {
                required: "Voucher type is required",
              })}
            >
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed</option>
            </select>
          </div>
        </div>
        {errors.voucher_value ? (
          <p className="text-red-500 text-xs">
            {errors.voucher_value?.message}
          </p>
        ) : errors.voucher_type ? (
          <p className="text-red-500 text-xs">{errors.voucher_type?.message}</p>
        ) : (
          <></>
        )}

        {/* Max Usage field */}
        <div className="font-medium mt-2">
          Maximum Use<span className="text-red-500">*</span>
        </div>
        <div
          className={`flex border rounded w-full ${
            errors.maximum_use && "border-red-500"
          }`}
        >
          <input
            className="w-full p-1"
            type="number"
            {...register("maximum_use")}
          />
          <div className="bg-gray-400 pt-1 font-semibold object-center align-middle px-2 text-gray-50">
            qty
          </div>
        </div>
        {errors.maximum_use && (
          <p className="text-red-500 text-xs">{errors.maximum_use?.message}</p>
        )}

        {/* expired date field */}
        <div className="grid grid-cols-2 mt-2">
          <div className="font-medium my-auto w028">
            Expired Date<span className="text-red-500">*</span>
          </div>
          <input
            type="datetime-local"
            className={`input-field-full-width 
            ${errors.expired_date && "border-red-500"}`}
            {...register("expired_date")}
          />
        </div>
        {errors.expired_date && (
          <p className="text-red-500 text-xs">{errors.expired_date?.message}</p>
        )}

        {/* Description field */}
        <div className="font-medium w-28 mt-2">Description</div>
        <div className="w-full">
          <input
            className="input-field-full-width"
            type="text"
            {...register("description")}
          />
        </div>

        <input
          type="hidden"
          {...register("products", {
            required: "Product is required",
          })}
        />

        <div className="mt-4 flex flex-col">
          <div className="block ml-auto mr-0">
            <button
              className="button-yellow-outline-with-hover"
              type="button"
              onClick={addNewProduct}
            >
              Manage Product
            </button>
          </div>
          {errors.products && (
            <p className="text-red-500 text-xs">
              {errors.products?.message as string}
            </p>
          )}
          <div className="mt-2">
            <table className="table-auto border w-full">
              <thead className="p-2 shadow-sm bg-gray-100 sticky">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Value</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {watch("products") ? (
                  Object.keys(watch("products")).map((key, index) => {
                    let item = watch("products")[key];
                    return (
                      <tr key={item.id}>
                        <td className="text-center p-2">{index + 1}</td>
                        <td className="text-center">{item.title}</td>
                        <td className="text-center">
                          <input
                            className="text-center"
                            type="number"
                            {...register(`products.${key}.value`)}
                            defaultValue="0"
                          />
                        </td>
                        <td className="text-red-500">
                          <AiOutlineMinusCircle
                            size={24}
                            onClick={() => onRemoveProduct(key)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className="text-center bg-gray-300 p-4">
                        No Product Available!
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex mt-3 float-right">
          <button
            className="button-gray-outline-with-hover mr-2"
            type="button"
            onClick={onSaveToDraft}
          >
            Save to Draft
          </button>
          <button className="button-yellow-outline-with-hover" type="submit">
            Publish
          </button>
        </div>
      </form>
    </div>
  );
};
