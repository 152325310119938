/* eslint-disable react-hooks/exhaustive-deps */
import { ContentHeader } from "@Components/ContentHeader.component";
import { Tabs } from "@Components/tabs/Tabs.component";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { FC, useEffect, useRef, useState } from "react";
import { SettingRequest } from "../data/settings.model";
import { BulkUpdateSettingsUsecase } from "../domain/BulkUpdateSettings.usecase";
import { GetAllSettingsUsecase } from "../domain/GetAllSettings.usecase";
import {
  AFFILIATE_DISCOUNT_AMOUNT,
  AFFILIATE_MINIMUM_WITHDRAW_AMOUNT,
  AFFILIATE_TELEGRAM_GROUP,
  AFFILIATE_TUTORIAL_VIDEO_LINK,
  CHECKOUT_ADDITIONAL_INFO,
  GET_RESPONSE_API_KEY,
  MIDAS_ADMIN_BASE_URL,
  MIDAS_API_BASE_URL,
  MIDAS_USER_BASE_URL,
  RESOURCE_SEPARATOR,
  SETTING_AFFILIATE_KEYS,
  SETTING_SITE_KEYS,
  XENDIT_CALLBACK_TOKEN,
  XENDIT_SECRET_KEY,
  DISCUSSION_GROUP,
  STRATEGY_ROOM,
  PORTOFOLIO_GROUP,
  STOCK_TREASURE_MAPS_TOOLS,
  STOCK_PORTOFOLIO_TOOLS,
  EBOOK_URL,
  LINK_ZOOM_WM,
} from "../utils/const";
import { CheckoutSection } from "./sections/Checkout.section";
import { GetJarvisFolderListUseCase } from "../domain/GetJarvisFolderList.usecase";
import { JarvisDetailModel } from "../data/jarvis.model";
import { GetJarvisFileListUseCase } from "../domain/GetJarvisFileList.usecase";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { EmitenBySectorsSection } from "./sections/EmitenBySectors.section";

export type SettingsFormInput = {
  id?: number;
  key: string;
  value: string;
  apiValue?: string;
  type?: string;
  label?: string;
};

export const SettingsPage: FC = () => {
  const toasterContext = UseToasterContext();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [needRefetch, setNeedRefetch] = useState(true);
  const [formData, setFormData] = useState<SettingsFormInput[]>([]);
  const [affiliateFormData, setAffiliateFormData] = useState<
    SettingsFormInput[]
  >([]);

  const [jarvisFolderIds, setJarvisFolderIds] = useState<JarvisDetailModel[]>();
  const [currentLoadIndex, setCurrentLoadIndex] = useState<number>();
  const [refreshJarvisText, setRefreshJarvisText] = useState<string>();
  const [totalTime, setTotalTime] = useState(0);

  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (needRefetch) {
      getGeneralSettings();
      getAffiliateSettings();
      getJarvisFolderIds();
      setNeedRefetch(false);
    }
  }, [needRefetch]);

  useEffect(() => {
    fetchJarvisFiles();
  }, [currentLoadIndex]);

  const getGeneralSettings = async () => {
    try {
      let processedData: SettingsFormInput[] = [];
      let response = await GetAllSettingsUsecase();

      let data = response.data;
      data.forEach((item) => {
        let newData = convertToGeneralFormData(
          item.id,
          item.option_key,
          item.option_value
        );
        if (newData !== null) {
          processedData.push(newData);
        }
      });

      setFormData(processedData);
    } catch (e) { }
  };

  const getAffiliateSettings = async () => {
    try {
      let processedData: SettingsFormInput[] = [];
      let response = await GetAllSettingsUsecase();

      let data = response.data;
      data.forEach((item) => {
        let newData = convertToAffiliateFormData(
          item.id,
          item.option_key,
          item.option_value
        );
        if (newData !== null) {
          processedData.push(newData);
        }
      });

      setAffiliateFormData(processedData);
    } catch (e) { }
  };

  const getJarvisFolderIds = async () => {
    try {
      let response = await GetJarvisFolderListUseCase();
      setJarvisFolderIds(response);
    } catch (e) { }
  };

  const fetchJarvisFiles = async () => {
    if (
      currentLoadIndex !== undefined &&
      jarvisFolderIds &&
      currentLoadIndex >= 0 &&
      currentLoadIndex < jarvisFolderIds.length
    ) {
      try {
        let jarvisData = jarvisFolderIds[currentLoadIndex];
        let folderId = jarvisData?.driveId ?? "";
        if (folderId === "") return;

        let text = refreshJarvisText ?? "";
        text += `Processing Folder "${jarvisData?.name}"...\n`;
        setRefreshJarvisText(text);
        bottomRef?.current?.scrollIntoView({ behavior: "smooth" });

        let response = await GetJarvisFileListUseCase(folderId);
        setRefreshJarvisText(
          text +
          `${response.filesCount} files found in ${jarvisData?.name}...\n${response.subFolders.length} folders found in ${jarvisData?.name}\nProcess time : ${response.time} seconds\n\n`
        );
        setTotalTime(totalTime + response.time);
        bottomRef?.current?.scrollIntoView({ behavior: "smooth" });

        let currentFolderIds = [...jarvisFolderIds];
        currentFolderIds[currentLoadIndex].status = true;

        setJarvisFolderIds([...currentFolderIds, ...response.subFolders]);
        setCurrentLoadIndex(currentLoadIndex + 1);
      } catch (e) { }
    } else if (currentLoadIndex && currentLoadIndex > 0) {
      let text = refreshJarvisText ?? "";
      text += `Done with Total Process Time ${totalTime} seconds.`;
      setRefreshJarvisText(text);
    }
  };

  const convertToGeneralFormData = (id: number, key: string, value: string) => {
    if (SETTING_SITE_KEYS.includes(key)) {
      let data: SettingsFormInput = {
        id: id,
        key: key,
        value: value,
        apiValue: value,
      };

      switch (key) {
        case RESOURCE_SEPARATOR:
          data["type"] = "text";
          data["label"] = "Resource Separator";
          break;
        case MIDAS_API_BASE_URL:
          data["type"] = "text";
          data["label"] = "API Base Url";
          break;
        case MIDAS_ADMIN_BASE_URL:
          data["type"] = "text";
          data["label"] = "Admin Base Url";
          break;
        case MIDAS_USER_BASE_URL:
          data["type"] = "text";
          data["label"] = "User Base Url";
          break;
        case XENDIT_SECRET_KEY:
          data["type"] = "text";
          data["label"] = "Xendit API Key";
          break;
        case XENDIT_CALLBACK_TOKEN:
          data["type"] = "text";
          data["label"] = "Xendit Callback Token";
          break;
        case CHECKOUT_ADDITIONAL_INFO:
          data["type"] = "dynamic_form";
          data["label"] = "Additional Info List";
          break;
        case GET_RESPONSE_API_KEY:
          data["type"] = "text";
          data["label"] = "Get Response API Key";
          break;
        case AFFILIATE_MINIMUM_WITHDRAW_AMOUNT:
          data["type"] = "text";
          data["label"] = "Minimum Withdraw Amount";
          break;
        case AFFILIATE_DISCOUNT_AMOUNT:
          data["type"] = "text";
          data["label"] = "Affiliate Discount Amount";
          break;
        case DISCUSSION_GROUP:
          data["type"] = "text";
          data["label"] = "Discussion Group Membership";
          break;
        case STRATEGY_ROOM:
          data["type"] = "text";
          data["label"] = "Strategy Room Membership";
          break;
        case PORTOFOLIO_GROUP:
          data["type"] = "text";
          data["label"] = "Portofolio Announcement Group";
          break;
        case STOCK_TREASURE_MAPS_TOOLS:
          data["type"] = "text";
          data["label"] = "Stock Treasure Maps Tools";
          break;
        case STOCK_PORTOFOLIO_TOOLS:
          data["type"] = "text";
          data["label"] = "Stock Portofolio Tools";
          break;
        case EBOOK_URL:
          data["type"] = "text";
          data["label"] = "Ebook Url";
          break;
        case LINK_ZOOM_WM:
          data["type"] = "text";
          data["label"] = "Link Zoom Weekly Meeting";
      }

      return data;
    } else {
      return null;
    }
  };

  const convertToAffiliateFormData = (
    id: number,
    key: string,
    value: string
  ) => {
    if (SETTING_AFFILIATE_KEYS.includes(key)) {
      let data: SettingsFormInput = {
        id: id,
        key: key,
        value: value,
        apiValue: value,
      };

      switch (key) {
        case AFFILIATE_MINIMUM_WITHDRAW_AMOUNT:
          data["type"] = "text";
          data["label"] = "Minimum Withdraw Amount";
          break;
        case AFFILIATE_DISCOUNT_AMOUNT:
          data["type"] = "text";
          data["label"] = "Affiliate Discount Amount";
          break;
        case AFFILIATE_TUTORIAL_VIDEO_LINK:
          data["type"] = "text";
          data["label"] = "Minimum Tutorial Video Youtube URL";
          break;
        case AFFILIATE_TELEGRAM_GROUP:
          data["type"] = "text";
          data["label"] = "Affiliate Telegram Group URL";
          break;
      }

      return data;
    } else {
      return null;
    }
  };

  const onSubmitGeneral = () => {
    let optionsToBeUpdate: SettingRequest[] = [];

    formData.forEach(function (item) {
      if (item.key !== "CHECKOUT_ADDITIONAL_INFO") {
        if (item.value !== item.apiValue) {
          optionsToBeUpdate.push({
            key: item.key ?? "",
            value: item.value ?? "",
          });
        }
      }
    });

    if (optionsToBeUpdate.length > 0) {
      BulkUpdateSettingsUsecase(JSON.stringify(optionsToBeUpdate))
        .then((message) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Settings updated successfully!",
              message,
              ToasterType.SUCCESS
            ),
          ]);
          setNeedRefetch(true);
        })
        .catch((errors) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to update settings!",
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        });
    }
  };

  const onSubmitAffiliate = () => {
    let optionsToBeUpdate: SettingRequest[] = [];

    affiliateFormData.forEach(function (item) {
      if (item.key !== "CHECKOUT_ADDITIONAL_INFO") {
        if (item.value !== item.apiValue) {
          optionsToBeUpdate.push({
            key: item.key ?? "",
            value: item.value ?? "",
          });
        }
      }
    });

    if (optionsToBeUpdate.length > 0) {
      BulkUpdateSettingsUsecase(JSON.stringify(optionsToBeUpdate))
        .then((message) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Settings updated successfully!",
              message,
              ToasterType.SUCCESS
            ),
          ]);
          setNeedRefetch(true);
        })
        .catch((errors) => {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              "Failed to update settings!",
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        });
    }
  };

  const buildViewBasedOnTab = () => {
    return selectedTabIndex === 0 ? (
      buildGeneralView()
    ) : selectedTabIndex === 1 ? (
      buildAffiliateView()
    ) : selectedTabIndex === 2 ? (
      buildCheckoutView()
    ) : selectedTabIndex === 3 ? (
      buildRefreshJarvisView()
    ) : selectedTabIndex === 4 ? (
      <EmitenBySectorsSection />
    ) : (
      <div className="text-center">Coming Soon</div>
    );
  };

  const buildRefreshJarvisView = () => {
    return (
      <div className="mt-4">
        {jarvisFolderIds && jarvisFolderIds.length === 0 && <MidasShimmering />}

        {jarvisFolderIds && jarvisFolderIds.length > 0 && (
          <>
            <ContentHeader
              title={"Refresh Jarvis Data"}
              rightButton={
                <button
                  className="button-yellow-outline-with-hover"
                  type="button"
                  onClick={() => {
                    setCurrentLoadIndex(0);
                  }}
                >
                  Run
                </button>
              }
            />
            <div className="h-6"></div>

            {refreshJarvisText && (
              <div className="w-full h-[500px] overflow-auto rounded border shadow bg-gray-400 p-4">
                {refreshJarvisText.split("\n\n").map((fullText, index) => (
                  <div key={`${index}-separator`}>
                    {fullText.split("\n").map((text, textIndex) => (
                      <div key={`${textIndex}-text`}>{text}</div>
                    ))}
                    <br />
                  </div>
                ))}
                <div ref={bottomRef}></div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const buildGeneralView = () => {
    return (
      <div className="mt-4">
        {formData.map(function (item, index) {
          return (
            item.type === "text" && (
              <div className="flex mt-2" key={item.key}>
                <label
                  className="font-medium my-auto w-32 md:w-64"
                  htmlFor={item.key.toLowerCase()}
                >
                  {item.label}
                </label>
                <input
                  className={"input-field-full-width"}
                  type={item.type}
                  name={item.key.toLowerCase()}
                  value={item.value}
                  onChange={(e) => {
                    let nFormData = [...formData];
                    nFormData[index] = {
                      ...nFormData[index],
                      value: e.target.value,
                    };
                    setFormData(nFormData);
                  }}
                />
              </div>
            )
          );
        })}

        <button
          className="button-yellow-outline-with-hover w-full mt-4"
          onClick={onSubmitGeneral}
        >
          Save
        </button>
      </div>
    );
  };

  const buildCheckoutView = () => {
    return (
      <>
        <CheckoutSection
          settingsData={formData}
          setNeedRefetch={setNeedRefetch}
        />
      </>
    );
  };

  const buildAffiliateView = () => {
    return (
      <div className="mt-4">
        {affiliateFormData.map(function (item, index) {
          return (
            item.type === "text" && (
              <div className="flex mt-2" key={item.key}>
                <label
                  className="font-medium my-auto w-32 md:w-64"
                  htmlFor={item.key.toLowerCase()}
                >
                  {item.label}
                </label>
                <input
                  className={"input-field-full-width"}
                  type={item.type}
                  name={item.key.toLowerCase()}
                  value={item.value}
                  onChange={(e) => {
                    let nFormData = [...affiliateFormData];
                    nFormData[index] = {
                      ...nFormData[index],
                      value: e.target.value,
                    };
                    setAffiliateFormData(nFormData);
                  }}
                />
              </div>
            )
          );
        })}

        <button
          className="button-yellow-outline-with-hover w-full mt-4"
          onClick={onSubmitAffiliate}
        >
          Save
        </button>
      </div>
    );
  };

  return (
    <div className="m-4">
      <ContentHeader title={"Settings"} />
      <div className="h-6"></div>

      <div>
        <Tabs
          tabItems={["General", "Affiliate", "Checkout", "Jarvis", "Emiten by Sectors"]}
          selectedIndex={selectedTabIndex}
          onClickTab={(index) => {
            setSelectedTabIndex(index);
          }}
        />
        {buildViewBasedOnTab()}
      </div>
    </div>
  );
};
