import { UsePageEditorContext } from "@Pages/page/context/page-editor.context";
import { FC } from "react";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { TextModel } from "../../models/text.model";

export type MidasWYSIWYGProps = {
  text: string;
  onTextChange: (newValue: string) => void;
};

export const MidasWYSIWYG: FC<MidasWYSIWYGProps> = ({ text, onTextChange }) => {
  const [currentValue, setCurrentValue] = useState(text);
 
  const List = ReactQuill.Quill.import("formats/list")
  const Size = ReactQuill.Quill.import("formats/size")
  const Link = ReactQuill.Quill.import("formats/link")

  List.className = "ql-li"
  Size.whitelist = ["small", "medium", "large", "huge"];
  Link.className = "ql-link"
  
  ReactQuill.Quill.register("formats/list", List);
  ReactQuill.Quill.register(Size, true);
  ReactQuill.Quill.register("formats/link", Link);

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],        // toggled buttons
    [{ "align": [] }],
    [{ "size": ["small", "medium", "large", "huge"] }],
    [{ "header": [false, 4] }],
    ["link","blockquote"],
    [{ "list": "ordered"}, { "list": "bullet" }],
    [{ "script": "sub"}, { "script": "super" }],  
    [{ "indent": "-1"}, { "indent": "+1" }], 
    [{ "color": [] }, { "background": [] }],          // dropdown with defaults from theme
    ["clean"]                                         // remove formatting button
  ];
  const modules = {
    toolbar: toolbarOptions
  }
  
  return (
    <ReactQuill
      value={currentValue}
      theme={"snow"}
      onChange={(value, delta, source, editor) => {
        onTextChange(value);
        setCurrentValue(value);
      }}
      modules={modules}
    />
  );
};

export const MidasTextEditor = () => {
  const { pageConfigs, setPageConfigs } = UsePageEditorContext();
  const [useEditor, setUseEditor] = useState(false);

  let editingModel: TextModel = pageConfigs.pageComponents[
    pageConfigs.editIndex
  ] as TextModel;

  const handleOpen = () => {
    setUseEditor(true);
  };

  const handleClose = () => {
    setUseEditor(false);
  };

  const onTextAreaChange = (event: any) => {
    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).text =
      event.target.value;

    let newConfig = {
      ...pageConfigs,
      pageComponents: pageComponents,
    };

    if (pageConfigs.oldModel == null || pageConfigs.oldModel === undefined) {
      newConfig["oldModel"] = editingModel;
    }

    setPageConfigs(newConfig);
  };

  const onTextEditorChanged = (newValue: string) => {
    let pageComponents = [...pageConfigs.pageComponents];
    (pageComponents[pageConfigs.editIndex] as TextModel).text = 
    `<div class="flex justify-center">
      <div class="w-full md:w-1/2 mx-4">
        <p> 
          ${newValue}
        </p><br />
      </div>
    </div> `;

    let newConfig = {
      ...pageConfigs,
      pageComponents: pageComponents,
    };

    if (pageConfigs.oldModel == null || pageConfigs.oldModel === undefined) {
      newConfig["oldModel"] = editingModel;
    }

    setPageConfigs(newConfig);
  };

  return (
    <div className="text-right">
      <textarea
        className="w-full p-1"
        onChange={onTextAreaChange}
        value={editingModel.text}
      />
      <button className="button-outline-with-hover" onClick={handleOpen}>
        Use Editor
      </button>

      {useEditor && (
        <div className="modal-container">
          <div className="modal-main">
            <div>
              <div className="flex justify-between">
                <div className="modal-title">Midas Editor</div>
                <div onClick={handleClose}>
                  <AiOutlineCloseCircle size={24} color="#FF0000" />
                </div>
              </div>
              <div>
                <div className="modal-description">
                  <MidasWYSIWYG
                    text={editingModel.text}
                    onTextChange={onTextEditorChanged}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
