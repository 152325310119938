import { PATH_RESOURCES } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { AxiosProgressEvent } from "axios";

export const UploadResourceCustomUsecase: (
  uploadProgressCallback: (progressEvent: AxiosProgressEvent, index: number) => void,
  title: string,
  file: File,
  index: number
) => Promise<boolean> = async (
  uploadProgressCallback,
  title,
  file,
  index
) => {
    try {
      let formData = new FormData();
      formData.append("name", title);
      formData.append("file", file);
      formData.append("file_name", title);

      await client.post(PATH_RESOURCES, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => uploadProgressCallback(e, index),
      });
      return true;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  };
