import { PATH_USERS_EXPIRED } from "@Utils/ApiConst";
import { client } from "@Utils/ApiService";
import { ListUserExpiredModel } from "../data/expiredUser.model";

export const GetExpiredUserUsecase: (queryParams: string) => Promise<ListUserExpiredModel> = async (
  queryParams
) => {
  try {
    let response = await client.get(PATH_USERS_EXPIRED + queryParams);
    return {
      list: response.data.data.list,
      meta: response.data.data.meta,
    };
  } catch (e) {
    throw new Error(e.response.data.message);
  }
};