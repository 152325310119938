import { ContentHeader } from "@Components/ContentHeader.component";
import { Tabs } from "@Components/tabs/Tabs.component";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { GetAffiliateUsersUsecase } from "../domain/GetAffiliateUsers.usecase";
import { AffiliateUserModel, listAffiliateUserModel } from "../data/AffiliateUser.model";
import { AffiliateUsersComponent } from "./components/AffiliateUsers.component";
import { GetAffiliateWithdrawRequestsUseCase } from "../domain/GetAffiliateWithdrawRequests.usecase";
import { AffiliateWithdrawModel } from "../data/AffiliateWithdraw.model";
import { AffiliateWithdrawComponent } from "./components/AffiliateWithdraws.component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { buildQueryParamsFromUrl, buildUrlSearchParams } from "@Helpers/utils";
import { REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE } from "@Helpers/regex";
import { SearchInputComponent } from "@Components/inputs/SearchInputs.component";
import { BottomListComponent } from "@Components/BottomList.component";
import { MetaModel } from "@Pages/common/data/meta.model";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";

export const AffiliatePage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [needRefresh, setNeedRefresh] = useState(true);
  const [affiliateUsers, setAffiliateUsers] = useState<AffiliateUserModel[]>();
  const [withdrawRequests, setWithdrawRequests] = useState<AffiliateWithdrawModel[]>();
  const [withdrawStatusFilter, setWithdrawStatusFilter] = useState<string>();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [needRefetch, setNeedRefetch] = useState(true);

  const searchRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [affiliateMeta, setAffiliateMeta] = useState<MetaModel | any>();

  useEffect(() => {
    if (needRefresh) {
      fetchWithdrawRequests();
      setNeedRefresh(false);
    }

    if (needRefetch) {
      fetchAffiliateUsers();
    }
  }, [needRefresh,needRefetch]);

  async function fetchWithdrawRequests(status?: string) {
    let responseData = await GetAffiliateWithdrawRequestsUseCase(status);
    setWithdrawRequests(responseData);
  }

  async function fetchAffiliateUsers() {
    let queryParams = buildQueryParamsFromUrl(searchParams.entries());
    if (queryParams !== "") {
      queryParams = "?" + queryParams;
    }

    try {
      let response = await GetAffiliateUsersUsecase(queryParams);
      setAffiliateUsers(response.affiliates);
      setAffiliateMeta(response.meta);
    } catch (errors) {
    }

    setNeedRefetch(false);
  }

  const onChangeTab = (index: number) => {
    setSelectedIndex(index);
  };

  const onSearchWithdrawRequest = (status: string) => {
    fetchWithdrawRequests(status);
    setWithdrawStatusFilter(status);
  };

  const onSearchAffiliateUser = (event: any) => {};

  const onWithdrawRequestStatusChange = (index: number, status: string) => {
    if (withdrawRequests) {
      let currentData = [...withdrawRequests];
      currentData[index] = {
        ...withdrawRequests[index],
        status: status,
      };

      setWithdrawRequests(currentData);
    }
  };

  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      let clearedString = e.target.value
        .replace(REGEX_EXCEPT_ALPHA_NUMERIC_AND_SPACE, "")
        .replaceAll(" ", "+");

      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(searchParams.entries(), "search", clearedString),
      });
    }
  };

  const canGoPrev = () =>
    (affiliateMeta && affiliateMeta.page > 1) ?? false;

  const onPrevPage = () => {
    if (affiliateMeta && canGoPrev()) {
      let prevPage = affiliateMeta.page - 1;
      if (prevPage >= 1) {
        setNeedRefetch(true);
        navigate({
          search:
            "?" +
            buildUrlSearchParams(
              searchParams.entries(),
              "page",
              prevPage.toString()
            ),
        });
      }
    }
  };

  const canGoNext = () =>
    (affiliateMeta && affiliateMeta.end < affiliateMeta.total) ?? false;

  const onNextPage = () => {
    if (affiliateMeta && canGoNext()) {
      let nextPage = affiliateMeta.page + 1;
      setNeedRefetch(true);
      navigate({
        search:
          "?" +
          buildUrlSearchParams(
            searchParams.entries(),
            "page",
            nextPage.toString()
          ),
      });
    }
  };
  
  const onChangeLimit = (newValue: string) => {
    setNeedRefetch(true);
    navigate({
      search:
        "?" + buildUrlSearchParams(searchParams.entries(), "limit", newValue),
    });
  };

  const buildViewTab = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <>
            {!withdrawRequests && <MidasShimmering />}

            {withdrawRequests && (
              <>
                <AffiliateWithdrawComponent
                  withdrawRequests={withdrawRequests}
                  statusFilter={withdrawStatusFilter ?? ""}
                  onFilterStatus={onSearchWithdrawRequest}
                  onStatusChanged={onWithdrawRequestStatusChange}
                />
              </>
            )}
          </>
        );
      case 1:
        return (
          <>
            {(!affiliateUsers || needRefetch) && <MidasShimmering />}
            
            {affiliateUsers && (
              <>
                <div className="border w-fit mb-4 rounded md:mt-0 flex">
                  <SearchInputComponent
                    searchRef={searchRef}
                    onKeyDown={handleSearch}
                  />
                </div>
                
                <AffiliateUsersComponent
                  affiliateUsers={affiliateUsers}
                  onSearching={onSearchAffiliateUser}
                />
              </>
            )}

            {affiliateMeta && (
              <BottomListComponent
                shouldShow={true}
                onLimitChange={onChangeLimit}
                canGoPrev={canGoPrev()}
                onGoToPrev={onPrevPage}
                canGoNext={canGoNext()}
                onGoToNext={onNextPage}
                meta={affiliateMeta}
              />
            )}
          </>
        );
    }
  };

  return (
    <div className="h-full overflow-auto m-4">
      <>
        <ContentHeader title={"Affiliate"} />
        <div className="mt-2">
          <Tabs
            tabItems={["Withdraw Requests", "Affiliate Users"]}
            selectedIndex={selectedIndex}
            onClickTab={onChangeTab}
          />
          <div className="border border-black rounded-b-xl shadow-2xl p-4 bg-white">
            {buildViewTab()}
          </div>
        </div>
      </>
    </div>
  );
};
