import { convertDateFromApiToView } from "@Helpers/formatter";
import { AffiliateUserModel } from "@Pages/affiliate/data/AffiliateUser.model";
import { FC } from "react";

export type AffiliateUsersProps = {
  affiliateUsers: AffiliateUserModel[] | undefined;
  onSearching: (event: any) => void;
};

export const AffiliateUsersComponent: FC<AffiliateUsersProps> = ({
  affiliateUsers,
  onSearching,
}) => {
  return (
    <div>
      {/* content */}
      <div className="mt-4">
        {/* Empty data */}
        {(!affiliateUsers || affiliateUsers?.length === 0) && (
          <div className="text-center bg-gray-300 p-4">
            Affiliate User Not Found!
          </div>
        )}

        {affiliateUsers && affiliateUsers.length > 0 && (
          <table className="table-auto border w-full">
            <thead className="p-2 shadow-sn bg-gray-100 sticky">
              <tr>
                <th className="border-l border-t border-b border-black"></th>
                <th className="border-t border-b border-black">Name</th>
                <th className="border-t border-b border-black">
                  Total Earnings
                </th>
                <th className="border-t border-b  border-black hidden md:table-cell">
                  Last Update
                </th>
                <th className="border-t border-b border-r border-black hidden md:table-cell">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {affiliateUsers.map((item, index) => (
                <tr key={`request-${index}-${item.affiliateName}`}>
                  <td className="border-l text-center border-t border-b border-black p-2">
                    {index + 1}
                  </td>
                  <td className="p-2 border-t border-b border-black">
                    <div className="font-bold text-ellipsis">
                      {item.user.fullname}
                    </div>
                    <div className="italic text-ellipsis">
                      {item.affiliateName ?? ""}
                    </div>
                    {/* <div className="flex mt-3">
                      <button
                        className="text-blue-400 mr-2 w-6 h-6"
                        title="View Details"
                      >
                        <FaEye />
                      </button>
                    </div> */}
                  </td>
                  <td className="text-center text-ellipsis p-2 border-t border-b border-black">
                    {item.totalEarningsStr}
                  </td>
                  <td className="text-center hidden md:table-cell p-2 border-t border-b border-black">
                    {convertDateFromApiToView(item.updatedAt ?? "")}
                  </td>
                  <td className="border-r text-center hidden md:table-cell p-2 border-t border-b border-black">
                    {item.status === "not_active" ? (
                      <div className="text-yellow-500">Not Active</div>
                    ) : item.status === "active" ? (
                      <div className="text-green-300">Active</div>
                    ) : item.status === "blocked" ? (
                      <div className="text-red-500">Blocked</div>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
