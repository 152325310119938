/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { AccordionComponent } from "@Components/accordion/Accordion.component";
import { ContentHeader } from "@Components/ContentHeader.component";
import { MidasShimmering } from "@Components/loaders/MidasShimmering.component";
import { convertDateFromApiToView } from "@Helpers/formatter";
import { OrderModel } from "../data/order.model";
import { GetOrderUsecase } from "../domain/GetOrder.usecase";
import { UseToasterContext } from "@Pages/common/contexts/toaster.context";
import { ToasterModel, ToasterType } from "@Components/toast/toast.model";
import { CompleteOrderUsecase } from "../domain/CompleteOrder.usecase";

export const DetailOrderPage = () => {
  const params = useParams();
  const toasterContext = UseToasterContext();

  const [needRefetch, setNeedRefetch] = useState(true);
  const [detail, setDetail] = useState<OrderModel | null>(null);

  useEffect(() => {
    if (needRefetch) {
      async function fetchData() {
        try {
          let response = await GetOrderUsecase(params.id ?? "");
          setDetail(response);
        } catch (errors) {
          toasterContext.setToastList([
            ...toasterContext.toastList,
            new ToasterModel(
              `Failed to fetch order with id ${params.id}`,
              errors.message,
              ToasterType.DANGER
            ),
          ]);
        }

        setNeedRefetch(false);
      }

      fetchData();
    }
  }, [needRefetch]);

  const onCompleteTransaction = async () => {
    try {
      await CompleteOrderUsecase(params.id ?? "");

      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          "Success to change Order",
          "Order status changed to Paid",
          ToasterType.SUCCESS
        ),
      ]);
      setNeedRefetch(true);
    } catch (errors) {
      toasterContext.setToastList([
        ...toasterContext.toastList,
        new ToasterModel(
          `Failed to fetch order with id ${params.id}`,
          errors.message,
          ToasterType.DANGER
        ),
      ]);
    }
  };

  return (
    <div className="p-4 h-full bg-white">
      {!detail && <MidasShimmering />}

      {detail && (
        <>
          <ContentHeader
            title={"Order Detail #" + detail.id}
            rightButton={
              detail.status === "waiting_for_payment" && (
                <button
                  className="button-yellow-outline-with-hover"
                  type="button"
                  onClick={onCompleteTransaction}
                >
                  Set as Paid
                </button>
              )
            }
          />
          <div className="h-6"></div>

          {/* xendit id */}
          <div className="w-full flex justify-evenly mt-4">
            <div className="w-full">Xendit ID</div>
            <div className="w-full font-bold">{detail.xendit_id}</div>
          </div>

          {/* status */}
          <div className="w-full flex justify-evenly mt-4">
            <div className="w-full">Status</div>
            <div className="w-full font-bold">
              {detail.deleted_at != null ? (
                <div className="text-red-500">In Trash</div>
              ) : detail.status === "in_progress" ? (
                <div className="text-yellow-500">In Progress</div>
              ) : detail.status === "waiting_for_payment" ? (
                <div className="text-yellow-500">Waiting for Payment</div>
              ) : detail.status === "paid" ? (
                <div className="text-green-500">Paid</div>
              ) : detail.status === "complete" ? (
                <div className="text-green-500">Complete</div>
              ) : detail.status === "failed" ? (
                <div className="text-red-500">Failed</div>
              ) : detail.status === "refunded" ? (
                <div className="text-orange-500">Refunded</div>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* Transaction Date */}
          <div className="w-full flex justify-evenly mt-4">
            <div className="w-full">Transaction Date</div>
            <div className="w-full font-bold">
              {convertDateFromApiToView(detail.created_at)}
            </div>
          </div>

          {/* Transaction Price */}
          <div className="w-full flex justify-evenly mt-4">
            <div className="w-full">Total Price</div>
            <div className="w-full font-bold">{detail.total_price_str}</div>
          </div>

          {/* Transaction Price */}
          {detail.voucher != null && (
            <div className="w-full flex justify-evenly mt-4">
              <div className="w-full">Voucher Code</div>
              <div className="w-full font-bold">{detail.voucher.code}</div>
            </div>
          )}

          {/* User Info */}
          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="User Details" isOpen={true}>
              <div className="flex flex-col p-4">
                <div className="flex justify-evenly">
                  <div className="w-full">Name</div>
                  <div className="w-full font-bold">
                    <Link 
                      to={`/users/detail/${detail.created_by.id}`}
                    >
                      {detail.created_by.fullname}
                    </Link>
                  </div>
                </div>
                <div className="flex justify-evenly">
                  <div className="w-full">Email</div>
                  <div className="w-full font-bold">
                    {detail.created_by.email}
                  </div>
                </div>
                <div className="flex justify-evenly">
                  <div className="w-full">Telegram</div>
                  <div className="w-full font-bold">
                    {detail.created_by.telegram ? (
                      `${detail.created_by.telegram}`
                      ) : (
                        "-"
                      )
                    }
                  </div>
                </div>
                <div className="flex justify-evenly">
                  <div className="w-full">No HP</div>
                  <div className="w-full font-bold">
                    {detail.created_by.phone ? (
                      `${detail.created_by.phone}`
                      ) : (
                        "-"
                      )
                    }
                  </div>
                </div>
                {/* <div className="flex justify-evenly">
                  <div className="w-full">Expired Date</div>
                  <div className="w-full font-bold">
                    {detail.created_by.member_active_roles &&
                    detail.created_by.member_active_roles.length > 0
                      ? detail.created_by.member_active_roles.sort((a, b) =>
                          new Date(a.expired_date) < new Date(b.expired_date)
                            ? -1
                            : 1
                        )[0].expired_date
                      : "-"}
                  </div>
                </div> */}
              </div>
            </AccordionComponent>
          </div>

          {/* Product List */}
          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Products List" isOpen={true}>
              <div className="flex flex-col p-4">
                <div className="mt-2">
                  <table className="table-auto border w-full">
                    <thead className="p-2 shadow-sm bg-gray-100 sticky">
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.items.map((item, index) => {
                        return (
                          <tr key={"product-" + item.id}>
                            <td className="text-center p-2">{index + 1}</td>
                            <td className="text-center">
                              {item.product.title}
                            </td>
                            <td className="text-center">
                              {item.total_price_str}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionComponent>
          </div>

          {/* Additional Info List */}
          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Additional Infos" isOpen={true}>
              <div className="flex flex-col p-4">
                <div className="mt-2">
                  <table className="table-auto border w-full">
                    <thead className="p-2 shadow-sm bg-gray-100 sticky">
                      <tr>
                        <th>No</th>
                        <th>Question</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detail.additional_info &&
                        JSON.parse(detail.additional_info).map(
                          (item: any, index: number) => {
                            return (
                              <tr key={"additional-info-" + index}>
                                <td className="text-center p-2">{index + 1}</td>
                                <td>{item.label}</td>
                                <td>{item.value}</td>
                              </tr>
                            );
                          }
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionComponent>
          </div>

          {/* Price Detail List */}
          <div className="w-full mt-4 border bg-white">
            <AccordionComponent title="Price Details" isOpen={true}>
              <div className="flex flex-col p-4 divide-y">
                <div className=" pb-2">
                  <div className="flex justify-evenly">
                    <div className="w-full">Total Price</div>
                    <div className="w-full text-right">
                      {detail.real_total_price_str}
                    </div>
                  </div>

                  {detail.voucher != null && (
                    <div className="flex justify-evenly">
                      <div className="w-full">Voucher</div>
                      <div className="w-full text-right">
                        Rp{detail.real_total_price - detail.total_price}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-evenly pt-2">
                  <div className="w-full font-bold text-lg">
                    Transaction Price
                  </div>
                  <div className="w-full text-right font-bold text-lg">
                    {detail.total_price_str}
                  </div>
                </div>
              </div>
            </AccordionComponent>
          </div>

          <div className="h-4"></div>
        </>
      )}
    </div>
  );
};
