import { ProductModel } from "@Pages/product/data/product.model";
import { FC } from "react";
import { useState } from "react";

export type SelectedProductModel = ProductModel & {
  id: number;
  title: string;
  value: number | null;
};

export type SelectedProductListVoucher = {
  [key: string]: SelectedProductModel;
};

export type ProductListVoucherProps = {
  parentSelectedProducts: SelectedProductListVoucher;
  onSelectProductChanged: (value: SelectedProductListVoucher) => void;
  productList: ProductModel[];
};

export const ProductListVoucher: FC<ProductListVoucherProps> = ({
  parentSelectedProducts,
  onSelectProductChanged,
  productList,
}) => {
  const [mSelectedProduct, setMSelectedProduct] =
    useState<SelectedProductListVoucher>(parentSelectedProducts);

  const addProduct = (index: number) => {
    let product = productList[index];
    let key = index.toString();

    onSelectProductChanged({
      ...mSelectedProduct,
      [key]: product as SelectedProductModel,
    });
    setMSelectedProduct({
      ...mSelectedProduct,
      [key]: product as SelectedProductModel,
    });
  };

  const removeProduct = (index: number) => {
    let selectedList = { ...mSelectedProduct };
    delete selectedList[index];

    onSelectProductChanged({ ...selectedList });
    setMSelectedProduct({ ...selectedList });
  };

  return (
    <div className="mt-4 h-[600px] relative">
      <table className="table-auto border w-full border-collapse shadow">
        <thead className="p-2 shadow-sm bg-gray-100 sticky">
          <tr>
            <th className="p-2 text-center border">No</th>
            <th className="p-2 text-center border">Name</th>
            <th className="p-2 text-center border"></th>
          </tr>
        </thead>
        <tbody>
          {productList.map((item, index) => (
            <tr key={item.id}>
              <td className="text-center border">{index + 1}</td>
              <td className="p-2 border">
                <div className="font-bold text-ellipsis">{item.title}</div>
              </td>
              <td className="text-center text-ellipsis p-2 border">
                {mSelectedProduct.hasOwnProperty(index) ? (
                  <button
                    className="button-red-outline-with-hover"
                    type="button"
                    onClick={() => removeProduct(index)}
                  >
                    -
                  </button>
                ) : (
                  <button
                    className="button-yellow-outline-with-hover"
                    type="button"
                    onClick={() => addProduct(index)}
                  >
                    +
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
